<template lang="pug">
.MainView
  Header(v-if="isAtril && !(view == 'Home' || view == 'Help' || view == 'HelpActive')" style="z-index: -845;")
  Header(v-if="!isAtril && !(view == 'Home' || view == 'Help' || view == 'HelpActive')" style="z-index: -845; margin-bottom: 15px;")
  template
    div(v-if="isAtril" style="position: fixed; left: 15px; top: 15px; z-index: 845;")
      small(@click="specialAction") versión {{ version }}
      h6(v-if="customArea" style="text-align: right; font-size: 0.7rem;") Area {{ customArea }}
      //- h6(style="text-align: right; font-size: 0.7rem;") Código {{ $route.params.code }}
    div(v-else style="position: fixed; right: 10px; top: 5px;")
      h6(style="text-align: right; font-size: 0.6rem; margin-bottom: 0;") V. {{ version }}
      h6(v-if="customArea" style="text-align: right; font-size: 0.6rem;") A. {{ customArea }}
      //- h6(style="text-align: right; font-size: 0.6rem;") Código {{ $route.params.code }}
  Progress(v-if="isAtril && view !== 'Home' && view !== 'Help' && view !== 'OutOperations' && view !== ''")
  Help(v-if="view == 'Help'")
  HelpActive(v-if="view == 'HelpActive'")
  CurrentUser(v-if="view == 'Home' || view == 'Help' || view == 'HelpActive'")
  //- QRView(v-else-if="view == 'QR'")
  OutOperations(
    :style="{ marginTop: isAtril ? '10vh' : '' }"
    v-else-if="view == 'OutOperations'"
    :withOutClose="isAtril"
    :withOutHeader="true"
  )
  EvaluationPriority(v-else-if="view == 'evaluationPriority'")
  NormativityBack(v-else-if="view == 'normativityBack'")
  NoDate(v-else-if="view == 'NoDate'")
  Quote(v-else-if="view == 'Quote'")
  ErrorAutenticate(v-else-if="view == 'ErrorAutenticate'")
  ErrorAutenticateVal(v-else-if="view == 'ErrorAutenticateVal'")
  Normativity(v-else-if="view == 'Normativity'")
  Pay(v-else-if="view == 'Pay'")
    template(#default)
      .d-flex(style="align-items: center; justify-content: center;")
        b-button.mt-4(variant="bluecolmedica" :style="colorComputed" @click="toHome")
          span Volver al inicio
  Turn(
    :tramiteName="tramiteName"
    v-else-if="view == 'Turn'"
    :userInfo="turnObject"
    :branchId="branch"
    :queueId="tramite"
    :source="source"
    :onlyGenerate="isAtril"
    :withAnalitycExtraFields="true"
    :waitVideoSource="waitVideoSource"
    :waitVideoPoster="waitVideoPoster"
    :hideVideo="hideVideo"
    @generatedTurn="printTicket"
    @endTurn="endTurn"
    @errorRequestTurn="toHome"
    @goBack="goBack"
  )
    template(#waitingActions v-if="isAtril")
      b-button(variant="bluecolmedica" @click="toHome" :style="isAtril ? 'font-size: 1.5rem;' + ' ' + colorComputed : colorComputed" v-if="!isAtril")
        span(style="font-size: 20px;") Volver al inicio
    //- TODO: Backend messages no
    //- template(#enqueue  v-if="currentValidation.length")
    //-   template(v-for="valid in currentValidation")
    //-     h5.mt-2.mb-2.text-middle(:style="colorStyle") {{ valid.DescripcionPaso }}
    template(#waiting="{ turn }")
      //- template(v-if="currentValidation.length")
        //- h4.mt-4.text-middle(
        //-   :class="isAtril ? 'h1 blue-text mt-3' : 'h1-mobile blue-text'" 
        //-   :style="isAtril ? 'font-weight: 900 !important;' : 'font-size: 30px; max-width: 300px; display: block; margin: auto; text-align: center;'"
        //- ) {{ currentValidation[0].DescripcionPaso }}
        //- h5.mt-4.text-middle(
        //-   :style="isAtril ? 'font-weight: 900 !important; color: #265BA5;' : 'padding-bottom: 40px; color: #265BA5; max-width: 300px; display: block; margin: auto; text-align: center; font-size: 20px; font-weight: 400 !important;'"
        //- ) {{ currentValidation[1].DescripcionPaso }}
      div(:class="isAtril ? 'text-align-center' : ' '")
        h4.mb-0.h1-modbile(
          :class="isAtril ? 'm-0 myTurnAtril' : 'mt-4'"
        ) Turno
        h3(:class="isAtril ? 'myTurnAtril' : 'mb-4'").text-middle.color-colmedica-light.font-weight-bold.m-0.h1-modbile {{ turn.myTurn }}
      //- h5.text-middle(:style="isAtril ? 'color: #545454;  font-size: 2rem;' : ' font-weight: bold !important; color: #545454; margin-top: 1rem !important;'").mt-5 {{isAtril ? "Favor toma el turno impreso" : ""}}
      //- h5.text-middle(:style="isAtril ? 'color: #545454;  font-size: 2rem;' : ' font-weight: bold !important; color: #545454;'").mt-0 {{isAtril ? "y procede a la sala de espera" : ""}}
    template(#endInfo)
      b-button.mt-4(variant="bluecolmedica" @click="toHome" :style="isAtril ? 'font-weigth: 100 !important; font-size: 1.5rem; margin: auto; display: block;' + ' ' + colorComputed : 'margin: auto; display: block;' + ' ' + colorComputed" v-if="!isAtril")
        span(:style="isAtril ? 'font-weigth: 100 !important;' : ''" style="font-size: 20px;") Volver al inicio
  .pending(v-else) Estamos verificando la sucursal.
</template>

<script>
// FIXME: Arreglar el footer, preguntar a Marcelo por si el footer no aparece, igual lo ponemos en estructura html?
import { mapActions, mapState } from "vuex";
import { getBranch } from "@/helpers/Meet";

// views
import CurrentUser from "./CurrentUser";
import BranchTimeValidation from "./BranchTimeValidation";
import OutOperations from "../OutOperations";
import NoDate from "./NoDate";
import Quote from "./Quote";
import Normativity from "./Normativity";
import ErrorAutenticateVal from "../autoservice/ErrorAutenticateVal";
import ErrorAutenticate from "./ErrorAutenticate.vue";
import Help from "./Help.vue";
import HelpActive from "./HelpActive";
import Turn from "../components/Turn";
import Pay from "./Pay";
import Header from "./components/Header";
import QRView from "./QRView";
import Progress from "../components/Progress";
import EvaluationPriority from "./EvaluationPriority.vue";
import NormativityBack from "./NormativityBack.vue";
import moment from "moment";

export default {
  name: "virtualrow",

  data: () => ({
    lastUpdate: null,
    timerReset: false,
    resetCounter: 0,
    timerCounter: null,
    intervalBranch: null,
    interval: null,
    userInteractity: true,
    company: null,
    seconds: 0,
    secondsSchedules: 0
  }),

  components: {
    CurrentUser,
    OutOperations,
    BranchTimeValidation,
    NoDate,
    Quote,
    ErrorAutenticate,
    Normativity,
    ErrorAutenticateVal,
    Help,
    HelpActive,
    Turn,
    Pay,
    Progress,
    Header,
    QRView,
    NormativityBack,
    EvaluationPriority
  },

  created() {
    this.setCustomArea(this.$route.query.area);
    window.document.title = "Turno virtual";
    if (location.hash.includes("fv_atril"))
      this.$store.commit("virtualrow/setState", {
        key: "isAtril",
        value: true
      });
    this.setBranchInfo(null);
    if (this.history.length === 0) this.BranchRequest(true);
    if (this.isAtril) {
      this.intervalBranch = setInterval(() => this.BranchRequest(), 1000);
      this.callAlive();
    }
  },

  mounted() {
    var events = ["mouseup", "keydown", "scroll", "mousemove"];
    let vue = this;

    // Check events
    events.forEach(function(e) {
      document.addEventListener(e, function() {
        // date last action
        vue.setUserActionDate(Date.now());
      });
    });

    // if (this.$route.name.includes("kids")) {
    //   this.goToView({ view: "Home" });
    // }
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalBranch);
  },

  watch: {
    $route(val) {
      this.setCustomArea(val?.query.area);
      if (val?.params.code)
        this.getMenus({
          code: val?.params.code,
          area: val?.query.area
        });
    },
    branchInfo(val) {
      if (val) this.firstViewCheck();
    },
    eventTime() {
      if (this.view == 'Turn') {
        this.$store.dispatch("turns/cancelTurnAction", true);
        const timer = setTimeout(() => {
          this.$store.dispatch("virtualrow/goToView", { view: "Home" });
          clearTimeout(timer);
        }, 200);
      } else {
        this.$store.dispatch("virtualrow/goToView", { view: "Home" });
      }
    },
    view(val, oldVal) {
      console.log(val);
      try {
        if (
          this.isAtril &&
          val === "Home" &&
          oldVal &&
          window.lastUpdate &&
          this.lastUpdate &&
          moment(this.lastUpdate).diff(moment(window.lastUpdate), "seconds") > 0
        )
          location.reload();
        // eslint-disable-next-line no-empty
      } catch (error) {}
      if (val === "Pay" && this.isAtril) {
        this.printPay(this.currentValidation);
      }
      if (this.reset) {
        this.timerReset = true;
        this.$store.dispatch("setTimer", true);
      }
      if (val == "Home") {
        setTimeout(() => {
          // Sure this is atril
          let origin = "fv_";
          if (location.hash.includes("fv_atril")) origin = "fv_atril_";

          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "Customer - Inicio",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: origin + this.currentSite?.type,
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);
      }
    },

    currentValidation(val) {
      if (val && val.length >= 1) {
        let data = {
          name: this.currentUser.Nombres,
          tipide_dni: this.currentUser.TipoIdentificacion,
          numide_dni: this.currentUser.NumeroIdentificacion,
          first_message: val[0]?.DescripcionPaso,
          second_message: val[1]?.DescripcionPaso,
          code_id: this.currentUser.CodigoOficina,
          branch_id: this.currentUser.branch_id || "0",
          origin: this.isAtril ? "Atril" : "Web"
        };
        this.saveRecord(data);

        try {
          let messagesDesc = {};
          for (let i = 0; i < val.length; i++) {
            let keys = Object.keys(val[i]);
            for (let ind = 0; ind < keys.length; ind++) {
              let key = keys[ind];
              messagesDesc[key + (i + 1)] = val[i][key];
            }
          }
          let dataMessage = {
            ...this.citaActual,
            ...messagesDesc,
            SucursalId: this.branchInfo.branch_id,
            DatosUsuario: this.currentUser
          };
          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "a-consultorio",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: `${JSON.stringify(dataMessage)}`
          };
          this.sendTracking(trackingData);
        } catch (error) {
          // tracking
          let trackingData = {
            uuid: window ? window.person?.id : "None",
            accion: "error-a-consultorio",
            debmedia_turn_code: "",
            url_origen: window ? window.location?.href : "None",
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: error
          };
          this.sendTracking(trackingData);
        }
      }
    }
  },

  computed: {
    ...mapState({
      branchInfo: state => state.branchInfo,
      view: state => state.virtualrow.view,
      history: state => state.virtualrow.history,
      sites: state => state.virtualrow.sites,
      currentSite: state => state.virtualrow.currentSite,
      customArea: state => state.virtualrow.customArea,
      actions: state => state.virtualrow.actions,
      currentUser: state => state.virtualrow.currentUser,
      currentValidation: state => state.virtualrow.currentValidation,
      priority: state => state.virtualrow.priority,
      citaActual: state => state.virtualrow.citaActual,
      sessionMaster: state => state.sessionMaster,
      isAtril: state => state.virtualrow.isAtril,
      selectedAccion: state => state.virtualrow.selectedAccion,
      eventTime: state => state.eventTime,
      reset: state => state.reset,
      restart: state => state.restart,
      version: state => state.version,
      env: state => state.env,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      dclient_data: state => state.dclient_data,
      userActionDate: state => state.virtualrow.userActionDate
    }),

    isEarlyArrival() {
      if (!this.citaActual) return false;
      const scheduledTime = moment(new Date()); // Enter the scheduled time in HH:mm format
      const userArrival = moment(new Date(this.citaActual.Fecha));
      const minutesDifference = userArrival.diff(scheduledTime, "minutes");
      return (
        minutesDifference < this.env.VUE_APP_DEBMEDIA_LATE_TIME &&
        minutesDifference > 0
      );
    },

    isLateArrival() {
      if (!this.citaActual) return false;
      const scheduledTime = moment(new Date()); // Enter the scheduled time in HH:mm format
      const userArrival = moment(new Date(this.citaActual.Fecha));
      const minutesDifference = userArrival.diff(scheduledTime, "minutes");
      return (
        minutesDifference >= this.env.VUE_APP_DEBMEDIA_EARLY_TIME * -1 &&
        minutesDifference <= 0
      );
    },

    colorStyle() {
      const style = this.isAtril
        ? {
            "font-size": "2rem",
            color: `#545454 !important`,
            "font-weight": "bold"
          }
        : {
            "font-weight": "bold !important",
            color: "#545454"
          };
      return style;
    },

    turnStyle() {
      const style = this.isAtril
        ? `font-size: 2.5rem; font-weight: bold; color: ${this.primaryColorBlue}`
        : `font-weight: bold !important; color: ${this.primaryColorBlue}; font-size: 2.3rem;`;
      return style;
    },

    inAttentionStyle() {
      const style = this.isAtril
        ? `font-size: 2rem; font-weight: bold; color: ${this.primaryColorBlue};`
        : `font-weight: bold !important; color: ${this.primaryColorBlue};`;
      return style;
    },

    waitVideoSource() {
      return (
        this.selectedAccion?.waiting?.video || this.currentSite?.waiting?.video
      );
    },

    waitVideoPoster() {
      return (
        this.selectedAccion?.waiting?.poster ||
        this.currentSite?.waiting?.poster
      );
    },

    evaluateCompany() {
      return this.env.VUE_APP_EVALUATE_COMPANY === "true";
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    hideVideo() {
      return (
        this.selectedAccion?.waiting?.hide ||
        this.currentSite?.waiting?.hide ||
        false
      );
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    prodmode() {
      return this.env.VUE_APP_ENV === "production";
    },

    letterCapPos() {
      return Number(this.env.VUE_APP_LETTERCAP_POS) || 40;
    },

    comp() {
      return this.env.VUE_APP_COMPANY;
    },

    defaultBranchId() {
      return this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH;
    },

    turnObject() {
      const value = !!this.selectedTramite?.priority_tramite_id;
      return {
        cedulaIdentidad: this.sessionMaster,
        cuil: this.sessionMaster,
        cuit: this.currentUser?.TipoIdentificacion,
        dni: this.currentUser?.NumeroIdentificacion,
        firstName: this.currentUser?.Nombres ?? "",
        lastName: "",
        phone: "",
        hasPriority: !value && this.priority
      };
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    branch() {
      return this.currentSite?.branch_id
        ? `${this.currentSite.branch_id}`
        : undefined;
    },

    selectedTramite() {
      let _action = (
        this.actions?.[this.customArea] ||
        this.actions?.default ||
        []
      ).find(br => br.actionCode === this.selectedAccion?.actionCode)
      // convert objects to final tramite id
      // _action = this.objectToId("tramite_id", _action)
      // _action = this.objectToId("late_tramite_id", _action)
      // _action = this.objectToId("early_tramite_id", _action)
      // _action = this.objectToId("priority_tramite_id", _action)
      // console.log("_action", _action)
      return _action;
    },

    tramite() {
      return this.isEarlyArrival
        ? this.selectedTramite?.early_tramite_id
        : this.isLateArrival
        ? this.selectedTramite?.late_tramite_id
        : this.selectedTramite?.tramite_id
        ? `${(this.priority && this.selectedTramite?.priority_tramite_id) ||
            this.selectedTramite?.tramite_id}`
        : undefined;
    },

    tramiteName() {
      return (
        this.selectedAccion?.printLabel || this.selectedAccion?.label || ""
      );
    },

    source() {
      return this.isAtril
        ? `fv_atril_${this.currentSite?.type ?? ""}/`
        : `fv_${this.currentSite?.type ?? ""}/`;
    }
  },

  methods: {
    ...mapActions({
      setCustomArea: "virtualrow/setCustomArea",
      getSites: "virtualrow/getSites",
      getStyles: "virtualrow/getStyles",
      getMenus: "virtualrow/getMenus",
      goToView: "virtualrow/goToView",
      cancelTurnAction: "turns/cancelTurnAction",
      goBack: "virtualrow/goBack",
      removeTimer: "removeTimer",
      setBranchInfo: "setBranchInfo",
      getCompany: "getCompany",
      saveRecord: "virtualrow/handleSendRecords",
      sendTracking: "virtualrow/handleTracking",
      setUserActionDate: "virtualrow/setUserActionDate"
    }),

    objectToId(key, action) {
      let _action = action;
      if(typeof action[key] == "object") {
        let _id
        if(this.currentUser.Tipo == "P") {
          _id = action[key].particular
        } else if(this.currentUser.Tipo == "C") {
          _id = action[key].client
        } else {
          _id = action[key].unidentified
        }
        _action[key] = _id
      }
      return _action
    },

    async BranchRequest(first = false) {
      // Check view
      if (this.view && this.view != "Home" && this.view != "OutOperations" && this.view != "Help")
        return;

      // Just if app is in use
      // let diff = moment(Date.now()).diff(moment(this.userActionDate), "seconds");
      // if(diff < (60*10)) { // 10 minutes
      this.seconds++;
      if (first || this.seconds == this.env.VUE_APP_POLLING_FREQUENCY) {
        this.seconds = 0;
        this.company = await this.getCompany(this.comp);
        let lastUpdate = this.company?._global_?.lastUpdate;
        if (lastUpdate && this.lastUpdate !== lastUpdate)
          this.lastUpdate = lastUpdate;
        await this.getSites();
        await this.getStyles();
        const finded = this.sites.find(
          site => site.code == this.$route.params.code
        );
        if (finded) {
          this.$store.commit("virtualrow/setState", {
            key: "currentSite",
            value: finded
          });
        }
        await this.getMenus({
          code: this.$route.params.code,
          area: this.$route.query.area
        });
      }

      // Check schedules
      this.secondsSchedules++;
      if (first || this.secondsSchedules == 10) {
        this.secondsSchedules = 0;
        let branchFiltered = this.branch
          ? Object.keys(this.company).find(
              name => this.company[name].branch_id == this.branch
            )
          : undefined;
        if (!this.company) return;
        if (!branchFiltered)
          branchFiltered = Object.keys(this.company).find(
            name => this.company[name].branch_id == this.defaultBranchId
          );
        this.setBranchInfo(await getBranch(this.comp, branchFiltered));
      }
    },

    callAlive() {
      if (typeof window.alive === "function") {
        this.interval = setInterval(() => {
          window.alive(true);
        }, 1000);
      } else console.log("Is'nt alive");
    },

    async firstViewCheck() {
      if (this.branchInfo) {
        if (this.isAtril) {
          if (
            (this.view === "Home" || this.view === "" || this.view === "Help") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "Home" });
          }
        } else {
          if (
            (this.view === "Home" || this.view === "") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "Home" });
          }
        }
      }
    },

    toHome() {
      if (this.isAtril) this.goToView({ view: "Home" });
      else location.reload();
    },

    async endTurn() {
      if (this.isAtril) await this.$store.dispatch("setTimer", true);
    },

    normalizer(str) {
      return typeof str === "string"
        ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        : str;
    },

    async printPay(list) {
      let posPrinterInfo = [
        {
          text: "",
          size: {
            width: 0,
            height: 0
          },
          style: "n",
          align: "ct"
        }
      ];
      for (const elm of list) {
        let listWords = elm.DescripcionPaso.split(" ");
        if (listWords.length <= 1)
          posPrinterInfo.push({ text: elm.DescripcionPaso });
        else {
          let letterCap = this.letterCapPos;
          let index = 0;
          let currentLine = [];
          let lines = [];
          while (listWords[index] !== undefined) {
            if (letterCap >= listWords[index].length) {
              letterCap -= listWords[index].length;
              letterCap -= 1; // Remove space
              currentLine.push(listWords[index]);
              index++;
            } else {
              lines.push(currentLine);
              currentLine = [];
              letterCap = this.letterCapPos;
            }
          }
          if (currentLine.length) lines.push(currentLine);
          for (let i = 0; i < lines.length; i++) {
            const lin = lines[i];
            posPrinterInfo.push({ text: lin.join(" ") });
          }
        }
        posPrinterInfo.push({ text: "" });
      }
      posPrinterInfo = [
        ...posPrinterInfo,
        { text: "" },
        {
          text: moment().format("YYYY-MM-DD hh:mm a"),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `https://tickets.gobrilliant.com/colmedica/turnopay.html?date=${
        encodeURIComponent(moment().format("DD/MM/YYYY"))
      }&hour=${
        encodeURIComponent(moment().format("hh:mm a").replace("am", "a.m.").replace("pm", "p.m."))
      }&${list.reduce(
        (prev, curr) =>
          prev + "ev=" + encodeURIComponent(curr.DescripcionPaso) + "&",
        ""
      )}`;
      console.log(url);
      let electronPrinter =
        typeof window.ElectronPrinter === "function"
          ? window.ElectronPrinter
          : window.ipcRenderer.ElectronPrinter;
          console.log("electronPrinter");
      console.log(electronPrinter);
      if (typeof electronPrinter === "function") {
        try {
          await electronPrinter({
            mode: "printURL",
            url,
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    async printTicket(info) {
      if (this.isAtril) await this.$store.dispatch("setTimer", true);
      let turn = info.turnInfo?.jsonDetails.turn ?? "NA";
      let tramite = this.tramiteName;
      let branch = info.turnInfo?.branch?.label;
      let queue = info.turnInfo?.jsonDetails.queue?.name ?? "NA";
      console.log(info)
      let posPrinterInfo = [
        { text: "" },
        {
          text: this.normalizer(turn),
          size: {
            width: 2,
            height: 2
          },
          style: "bu",
          align: "ct"
        },
        { text: "" },
        {
          text: this.normalizer(tramite),
          size: {
            width: 0,
            height: 1
          },
          style: "n"
        },
        { text: "" },
        {
          text: this.normalizer(branch),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: this.normalizer(queue) },
        { text: "" },
        { text: moment().format("YYYY-MM-DD hh:mm a") },
        { text: "" },
        { text: "" },
        { text: "" }
      ];

      // select template
      let typeTicket = "turno.html"
      if(this.currentSite?.type == "cm") {
        typeTicket = "turnocm.html"
      }
      // if(this.currentUser?.TipoIdentificacion == "RC") {
      //   typeTicket = "turnorc.html"
      // }

      let url = `https://tickets.gobrilliant.com/colmedica/${typeTicket}?tramite=${
        encodeURIComponent(tramite)
      }&turn=${
        turn
      }&branch=${
        encodeURIComponent(branch)
      }&queue=${
        encodeURIComponent(queue)
      }&date=${
        encodeURIComponent(moment().format("DD/MM/YYYY"))
      }&hour=${
        encodeURIComponent(moment().format("hh:mm a").replace("am", "a.m.").replace("pm", "p.m."))
      }&sitename=${
        encodeURIComponent(this.currentSite?.name)
      }`;

      // add username in URL
      if(this.currentUser?.Nombres) {
        url = url+`&currentusername=${encodeURIComponent(this.currentUser?.Nombres || "No Idenfiticado")}`
      }

      console.log("To print", url);
      console.log("postPrinter");

      let electronPrinter =
        typeof window.ElectronPrinter === "function"
          ? window.ElectronPrinter
          : window.ipcRenderer.ElectronPrinter;
      console.log("electronPrinter");
      console.log(electronPrinter);
      if (typeof electronPrinter === "function") {
        try {
          await electronPrinter({
            mode: "printURL",
            url,
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    specialAction() {
      if (!this.isAtril) return;
      this.resetCounter++;
      if (this.timerCounter) clearTimeout(this.timerCounter);
      if (this.resetCounter > 4) {
        this.resetCounter = 0;
        location.reload();
      } else {
        this.timerCounter = setTimeout(() => {
          this.resetCounter = 0;
          this.timerCounter = null;
        }, 2000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.Container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box_size {
  max-width: 100vw;
  margin: 0px auto;
  margin-bottom: 10px; /*35*/
  font-family: Helvetica-light;
  font-size: 1.2rem;
  font-weight: bold;
  color: #545454;
}

.pending {
  text-align: center;
  margin-top: 2rem;
}

.dk-pos {
  position: absolute;
  top: -12px;
  display: flex;
  left: calc(100vw - 14rem);
  justify-content: flex-end;
  width: fit-content;
}

.box_size.atril {
  margin: 0px auto;
  max-width: 1302px;
  margin-bottom: 0;
  font-size: 1.7rem !important;
  font-weight: bold;
  color: #545454;
}

.box_size.atril * {
  font-weight: bold !important;
}

.box_size.atril .btn {
  font-size: 1.7rem !important;
}

.box_size.atril .h1 {
  font-size: 2.7rem;
  font-weight: bold !important;
}

.dk-pos-dk {
  position: absolute;
  top: -67%;
  left: 0px;
  right: 0px;
}

.dk-overlay {
  background-color: rgb(255 255 255 / 82%);
  position: relative;
  padding: 1rem;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
}

.h1-modbile {
  color: var(--color-primary);
  font-size: 60px;
  margin-bottom: 0;
}

.myTurnAtril,
.myTurnAtril p {
  font-size: 80px;
}

@media only screen and (min-width: 999px) {
  .box_size {
    max-width: 500px;
  }
}

@media screen and (max-height: 720px) {
  .dk-pos-dk {
    top: 22%;
  }
}
</style>
