<template lang="pug">
.Header(:class="isAtril ? 'client_logo' : 'client_log_mobile'" :style="topStyle")
  div(style="margin: 0px auto;")
    img(:style="logoStyle" :src="Logo" v-openlog)
</template>
<script>
// import LogoColmedica from "@/assets/colmedica_logo.png";
import LogoColmedica2 from "@/assets/LogoColmedica.svg";
import { mapState } from "vuex";

export default {
  name: "Header",

  props: {
    topClass: {
      type: String,
      default: "col-12 header"
    },
    topStyle: {
      default: () => ({})
    },
    logoStyle: {
      default: () => ({})
    }
  },

  data: () => ({
    LogoColmedica: LogoColmedica2
  }),

  computed: {
    ...mapState({
      env: state => state.env,
      isAtril: state => state.virtualrow.isAtril
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.LogoColmedica;
    }
  }
};
</script>
<style scoped>
.header {
  text-align: center;
  padding-top: 22px;
}

.client_logo {
  max-width: 362px;
  max-height: 105px;
  height: auto;
  margin: auto;
  padding-top: 20px;
  margin-bottom: 30px;
}

.client_log_mobile {
  max-width: 170px;
  max-height: 58px;
  height: auto;
  margin: auto;
  margin-top: 15px;
}

.client_log_mobile img, .client_logo img {
  width: 100%;
  height: 100%;
}
</style>
