<template lang="pug">
div(
  :style="isAtril ? 'color: #545454; width: 100%; margin: auto; min-height: 700px;' : 'margin: auto; width: 100%; font-size: 1.3rem;'"
  :class="isAtril ? '' : 'NoDate Container'"
)
  .Content(:style="!isAtril ? 'padding-top: 15px;' : ''")
    div.m-auto(:style="isAtril ? 'display: flex; justify-content: center; flex-direction: column; align-items: center;' : ''")
      .h(
        :class="isAtril ? 'h1 blue-text mt-3' : 'h1-mobile blue-text'" 
        :style="isAtril ? 'font-weight: 900 !important; top: 22% !important;' : 'max-width: 300px; display: block; margin: auto; text-align: center;'"
      ) {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
      p.mt-3.box-red.mb-5(v-if="isAtril") {{ actualTime() }}
    p.mt-1.box-red.mb-4.mx-5(v-if="isAtril && name"): strong Hola {{ name }}
    div.m-auto(:style="isAtril ? 'display: flex; justify-content: center; flex-direction: column; align-items: center;' : ''")
      p(v-if="citasEnCM.length > 0").text-start.sub-mobile.mt-5.mb-4(:style="isAtril && 'font-size: 2rem; margin: 0 !important;'") Selecciona la cita a la que vienes
    p(v-if="citasFueraCM.length" :style="isAtril ? 'margin-bottom: 0.5rem; margin-top: 1rem;' : 'margin-top: 0.5rem;'").text-center.mt-4
    .overlay-charg(v-if="charging")
    .charging-text(v-if="charging")
      p Estamos verificando la información de tu cita
      p Por favor espera
    
    div(:style="isAtril ? `height: ${citasEnCMList.length == 0 ? '0px' : (citasEnCMList.length == 1 ? '250px' : '450px')}; overflow-y: auto; max-width: fit-content; margin: 0 auto; padding-right: 10px;` : ''")
      div(
        :class="isAtril ? 'cards-dk d-flex justify-content-between m-auto' : 'cards'"
        :style="isAtril ? 'flex-direction: column;' : 'padding-bottom: 100px;'"
        v-if="citasEnCMList && citasEnCMList.length"
      )
        .big_box.mt-4.mx-auto(
          v-for="citasEnCM in citasEnCMList"
          :style="isAtril ? 'position: relative; min-height: 160px; min-width: 600px; height: 210px; width: 800px;' : ''"
          @click="selectCita(citasEnCM)"
        )
          .dk-overlay
            .d-flex.flex-column
              p.m-0(:style="isAtril ? 'font-weight: bold !important; font-size: 40px; max-width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;' : 'font-size: 1.3rem; font-weight: bold;'") {{ citasEnCM.Especialidad }}
              p.consulta-priority(:style="isAtril ? 'font-weight: bold !important; font-size: 30px;' : ''") Consulta programada
            .d-flex.justify-content-between
              .d-flex.justify-content-between.align-items-center
                img(src="../../assets/images/icon-date.svg" alt="icon-date" :style="isAtril ? 'width: 45px; height: 50px;' : 'width: 25px; height: 25px;'")
                p.ml-2.m-0(:class="isAtril ? 'date-btm' : 'date-btm'" :style="isAtril ? 'font-size: 25px' : ''") {{ getDate(citasEnCM.Fecha) }}
              .d-flex.justify-content-between.align-items-center
                img(src="../../assets/images/icon-hour.svg" alt="icon-hour" :style="isAtril ? 'width: 45px; height: 50px;' : 'width: 25px; height: 25px;'")
                p.ml-2.m-0(:class="isAtril ? 'date-btm' : 'date-btm'" :style="isAtril ? 'font-size: 25px;' : ''") {{ getHour(citasEnCM.Fecha) }}
    div(
      :class="isAtril ? 'cards-dk d-flex justify-content-between m-auto' : 'cards'"
      :style="isAtril ? 'flex-direction: column;' : 'padding-bottom: 100px;'"
    )
      b-button.text-center(variant="outline-bluecolmedica" :style="isAtril ? 'width: 800px; z-index: 2; height: 80px !important;' : 'margin-top: 25px;'" :class="isAtril ? 'atril-btn-back mt-4 mx-auto' : ''" class="text-center" size="sm" type="button" @click="goToOtherServices")
        span.px-5()
          span(:style="isAtril ? 'font-size: 30px;' : 'font-size: 18px;'") Seleccionar otro servicio
    div(
      :class="isAtril ? 'cards-dk d-flex justify-content-between m-auto' : 'cards'"
      :style="isAtril ? 'flex-direction: column;' : 'padding-bottom: 100px;'"
    )
      p.px-5(@click="showModalOthers = true" style="display: block; text-align: center; margin-top: 15px; z-index: 2; color: #055BA4; text-decoration: underline; cursor: pointer;" :style="isAtril ? 'font-size: 30px;' : 'font-size: 18px;'")
        span(:style="isAtril ? 'font-size: 30px;' : 'font-size: 18px;'") Ver citas programadas en otros Centros Médicos
    b-modal(
      v-model="showModalOthers",
      hide-footer
    )
      h6.text-center(style="color: gray;" :style="isAtril && 'font-size: 1.4rem;'") Estas citas son de otros Centros Médicos
      .cards(style="padding-bottom: 100px")
        .big_box(v-for="cita in citasFueraCM", :key="cita.idCita").mb-1
          .dk-overlay
            h5(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Cita {{ cita.idCita }}.
            h6(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Nombre del centro médico: {{ cita.NombreCentroMedico }}
            p(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Especialidad: {{ cita.Especialidad }}
            p.m-0(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'") Fecha: {{ cita.Fecha }}
            //- p.m-0(:style="isAtril ? 'font-size: 1.6rem; font-weight: bold !important; color: #545454;' : 'font-size: 1.3rem; font-weight: bold;'"): small(style="font-weight: bold !important;") {{ cita.Fecha }}
            .buttons-footer.Footer
    .buttons.Footer(:style="isAtril ? 'z-index: 1;' : 'z-index: 1;'")
      .d-flex(:class="isAtril ? 'justify-content-between' : 'justify-content-between'")
        b-button(variant="whitecolmedica" :class="isAtril ? 'atril-btn-back' : ''" class="text-center" size="sm" type="button" @click="BackCalculed")
          svg(v-if="!isAtril" width='25' height='15' viewbox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M25 6.25H5.31944L10.2917 1.7625L8.33333 0L0 7.5L8.33333 15L10.2917 13.2375L5.31944 8.75H25V6.25Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
          svg(v-else width='58' height='35' viewbox='0 0 58 35' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M57.95 14.522H12.4635L23.9555 4.15114L19.4293 0.0779114L0.168945 17.4108L19.4293 34.7437L23.9555 30.6705L12.4635 20.2996H57.95V14.522Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
        b-button(v-if="!isAtril" variant="whitecolmedica" class="text-center" size="sm" type="button" @click="goHome")
          svg(width='22' height='19' viewbox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M21.5447 9.34433L11.5489 0.208078C11.4769 0.142118 11.3914 0.0897877 11.2973 0.054083C11.2032 0.0183783 11.1023 0 11.0005 0C10.8986 0 10.7977 0.0183783 10.7036 0.054083C10.6095 0.0897877 10.524 0.142118 10.452 0.208078L0.456229 9.34433C0.165019 9.6107 0 9.97251 0 10.3499C0 11.1334 0.696477 11.7705 1.55312 11.7705H2.60633V18.2897C2.60633 18.6826 2.95335 19 3.38289 19H9.44734V14.0279H12.1653V19H18.618C19.0476 19 19.3946 18.6826 19.3946 18.2897V11.7705H20.4478C20.8603 11.7705 21.2559 11.6217 21.5471 11.3532C22.1514 10.7982 22.1514 9.89926 21.5447 9.34433Z' fill='#FC0D0E' style='fill:#FC0D0E;fill:color(display-p3 0.9882 0.0510 0.0549);fill-opacity:1;')
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import AlertCircle from "mdi-vue/AlertCircle";
import MiniSpinner from "../components/MiniSpinner.vue";
import moment from "moment";

export default {
  name: "Quote",

  components: {
    Header,
    Back,
    Continue,
    AlertCircle,
    MiniSpinner
  },

  data() {
    return {
      showModal: false,
      showModalOthers: false
      // infoDir: "",
      // infoName: ""
    };
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  mounted() {
    if (this.currentUser.TipoIdentificacion === "RC") {
      document.body.style.backgroundImage =
        this.isAtril ? "url(" + require("@/assets/images/jirafa_atril.png") + ")" :
        "url(" + require("@/assets/images/doggy.png") + ")";
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrow.currentUser,
      currentSite: state => state.virtualrow.currentSite,
      citas: state => state.virtualrow.citas,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      seocndColorBlue: state => state.virtualrow.seocndColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      charging: state => state.virtualrow.charging
    }),

    citasEnCMList() {
      return this.citasEnCM;
    },

    name() {
      return this.currentUser.Nombres;
    },

    citaHora() {
      //TODO: Por ahora esto no const dateCita = new Date(this.citasEnCM[0].Fecha).getHours();
      //TODO: Esta es forma de solo poner hora const splitFecha = this.citasEnCM[0].Fecha.split(":");
      // console.log(splitFecha);
      // const result = `${splitFecha[0].slice(-2)}:${splitFecha[1]}`;
      return this.citasEnCM[0].Fecha;
    },
    

    computedText() {
      return this.citasEnCM.length >= 3
        ? `
          Para tus siguientes citas programadas, recuerda tomar turno antes de la hora de la cita
        `
        : this.citasEnCM.length == 2
        ? ` Para tu siguiente cita programada, recuerda tomar turno antes de la hora de la cita`
        : "";
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    principalText() {
      return this.citasEnCM.length
        ? this.citasEnCM.length === 1
          ? `Esta es la atención que tienes programada hoy en el Centro Médico ${this
              .currentSite?.name ??
              ""}.<span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
          : `Estas son las citas que tienes programadas para hoy en el Centro Médico ${this
              .currentSite?.name ??
              ""}. <span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
        : `No tienes citas programadas para hoy en el Centro Médico ${this
            .currentSite?.name ?? ""}`;
    },

    colorComputed() {
      return `white-space: no-wrap; background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    citasEnCM() {
      return this.citas.filter(cita => cita.EnCentroMedico);
    },

    citasFueraCM() {
      return this.citas.filter(cita => !cita.EnCentroMedico);
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      setCitaCM: "virtualrow/setCitaCM",
      goBack: "virtualrow/goBack",
      goToView: "virtualrow/goToView",
      removeTimer: "removeTimer",
      setTimer: "setTimer"
    }),
    validationAction() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    },
    BackCalculed() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goBack();
    },
    goToOtherServices() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "NoDate" });
    },
    goHome() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "Home" });
    },
    getDate(date) {
      return moment(date)
        .locale("es")
        .format("dddd[,] DD [de] MMMM [de] YYYY");
    },
    getHour(date) {
      return moment(date)
        .format("hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
    },
    actualTime() {
      const timer = setTimeout(() => {
        this.actualTime();
        clearTimeout(timer);
      }, 1000);
      let n = moment()
        .locale("es")
        .format("dddd DD [de] MMMM[,] hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
      return n[0].toUpperCase() + n.substring(1);
    },
    async selectCita(cita) {
      if (this.charging) return;
      /* eslint-disable */
        if (this.isAtril) await this.removeTimer();
        await new Promise(async r => {
          cita.r = r;
          this.setCitaCM(cita);
        }) 
        if (this.isAtril) await this.setTimer();
        /* eslint-enable */
    }
  }
};
</script>
<style scoped>
.Quote {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.Quote > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}

.box-dk {
  max-width: 488px;
  margin: 1.4rem auto;
  text-align: center;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 40px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgba(223, 230, 237, 0);
  margin: auto;
  z-index: -1;
  padding: 0 2rem;
  padding-top: 2rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.icon-alert {
  color: #4b5c6b;
  margin: 15px auto;
  display: block;
  max-width: 50px;
  text-align: center;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  display: block;
  margin: 3rem auto;
  text-align: center;
  min-height: 20rem;
}

.span svg {
  width: 50px;
  height: 50px;
  fill: gray;
}

.big_box {
  position: relative;
  scroll-snap-align: center;
}

.big_box::-webkit-scrollbar {
  display: none;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-link {
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  font-weight: 400;
  font-size: 14.3743px;
  line-height: 24px;
  /* identical to box height, or 164% */

  text-align: center;
  text-decoration-line: underline;

  /* TEXT/Títulos, sub títulos, body. */

  color: #283645;
}

.dk-link:hover {
  text-decoration: underline;
}

.cards-dk {
  display: flex;
  justify-content: center;
  margin: auto;
  /* max-width: 834px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  position: relative;
  min-height: 290px; */
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.date-btm {
  font-size: 14px;
  font-weight: 400;
  color: #044a6b;
}

.consulta-priority {
  color: #246faf;
  font-weight: 400;
  font-size: 18px;
}

.dk-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  border: none;
  background: #edeeee;
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.dk-overlay .specialPG {
  font-style: normal;
  font-weight: 400;
  font-size: 16.4277px;
  line-height: 24px;
  color: #081e3d;
  margin: 0;
}

.buttonService {
  width: 100%;
  max-width: 300.83px;
  padding: 1rem 2rem;
  background-color: #009dac;
  border-radius: 16.4277px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.is-charging {
  position: relative;
}

.overlay-charg {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 3030303030303030303030;
  transition: 1s opacity;
  border-radius: 15px;
}

.charging-text {
  font-style: normal;
  font-weight: 400;
  font-size: 40.4277px;
  line-height: 40px;
  color: #246faf;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 30303030303030303030303030303030303030303030;
  transform: translate(-50%, -50%);
}

.boxQuote {
  width: 100%;
  max-width: 432.46px;
}

.selectedSchedule {
  border: 1px solid #a1d23f;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .pos_dk {
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
  }
}
</style>
