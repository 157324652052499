<template lang="pug">
.Normativity.pb-3.Container(@click="actionActive" :style="isAtril ? 'margin: auto; width: 100%; align-items: start !important; overflow: hidden; max-width: 2500px;' : 'width: 100%;'")
  .Content
    div.m-auto(:style="isAtril ? 'display: flex; justify-content: center; flex-direction: column; align-items: center;' : ''")
      .h(
        :class="isAtril ? 'h1 blue-text mt-3' : 'h1-mobile blue-text'" 
        :style="isAtril ? 'font-weight: 900 !important; top: 22% !important;' : 'max-width: 300px; display: block; margin: auto; text-align: center;'"
      ) {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
      p.mt-3.box-red.mb-5(v-if="isAtril") {{ actualTime() }}
    //- article.Article(v-if="siteIsOffice")
    //-   p(style="isAtril ? 'color: #545454;' : 'color: gray;'") Por favor selecciona si requieres turno preferencial
    //-   .pb-3.pt-3.d-flex.flex-wrap.justify-content-center(:style="isAtril ? 'max-width: 800px;' : 'min-width: 298px;'")
    //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1
    //-       img(src="../../assets/cane.png" alt="cane").image
    //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
    //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1.ml-1
    //-       img(src="../../assets/pregment.png" alt="pregment").image
    //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
    //-     figure(@click="turnCall(true)" style="font-size: 16px;").mb-1
    //-       img(src="../../assets/invalido.png" alt="invalido").image
    //-       figcaption(v-if="currentSite.type === 'of'" style="color: #00599d;")
    //-   .d-flex.justify-content-around.pt-3(style="max-width: 265px; margin: auto;")
    //-     b-button(variant="bluecolmedica" @click="turnCall(false)" :style="isAtril ? 'font-size: 1.3rem; margin: auto; display: block;' : 'margin: auto; display: block;'").mb-5 No aplico para turno preferencial
    article.Article(:style="!isAtril ? 'color: #545454; margin-top: 40px;' : 'color: #545454; margin-top: 90px; max-width: 1500px; font-size: 21px; margin-bottom: 70px;'")
      p.text-center.mb-4.mt-5.sub-mobile(:class="isAtril ? 'pt-3' : ''" :style="isAtril ? 'font-size: 30px; text-align: start !important; font-weight: bold; margin-top: 30px;' : ''") ¿Qué tipo de atención deseas?
      div(:style="isAtril ? 'display: flex; padding-bottom: 30px; gap: 63px;' : ''")
        ul(style=`
          list-style: none;
          text-align: start;
          margin: 0;
          flex: 1;
        `
        :style="isAtril ? 'padding: 21px 71px; padding-top: 70px;' : ''"
        @click="turnCall(true)"
        ).card-priority.mr-2.ml-2
          div(:style="isAtril ? 'display: flex; align-items: center; justify-content: center; gap: 77px;' : ''")
            li(:style="isAtril ? 'display:flex; padding: 0 40px;justify-content: center; align-items: center; flex-direction: column;' : 'display:flex;padding: 20px 0 20px 0px;justify-content: space-between;'").px-3
              img(src="../../assets/images/pregnant_woman.svg" :style="isAtril ? 'width: 108px; height: 108px;' : ''").size_icon
              .pad(:style="isAtril ? 'text-align: center; margin-top: 30px; font-size: 30px; line-height: 36px;' : ''") Embarazo
            li(:style="isAtril ? 'display:flex; padding: 0 40px;justify-content: center; align-items: center; flex-direction: column;' : 'display:flex;padding: 20px 0 20px 0px;justify-content: space-between;'").px-3 
              img(src="../../assets/images/wheelchair.svg" :style="isAtril ? 'width: 108px; height: 108px;' : ''").size_icon
              .pad(:style="isAtril ? 'text-align: center; margin-top: 30px; font-size: 30px; line-height: 36px;' : ''") Discapacidad
            li(:style="isAtril ? 'display:flex; padding: 0 40px;justify-content: center; align-items: center; flex-direction: column;' : 'display:flex;padding: 20px 0 20px 0px;justify-content: space-between;'").px-3
              img(src="../../assets/images/older.svg" :style="isAtril ? 'width: 108px; height: 108px;' : ''").size_icon 
              .pad(:style="isAtril ? 'width: 198px; text-align: center; margin-top: 20px; font-size: 30px; line-height: 36px;' : ''") Adulto mayor de 65 años
          b-button(variant="redcolmedica" @click="turnCall(true)" :class="isAtril ? 'p-4' : ''" class="text-center" size="sm" type="button" block :style="`margin-top: 20px; ${colorComputed}`")
            span(:style="isAtril ? 'font-size: 30px !important;' : ''") Con turno preferencial
        ul(style=`
          list-style: none;
          text-align: start;
          margin: 0;
          flex: 1;
        `
          :style="isAtril ? 'padding: 21px 71px; padding-top: 70px; margin: 0 !important;' : ''"
          @click="turnCall(false)"
        ).card-priority.mt-3
          li(style="display:flex;justify-content: center;").px-3
            img(:style="isAtril ? 'width: 170px; height: 170px; margin: 0 80px;' : ''" src="../../assets/images/anonymous.svg").size_icon
          b-button(variant="bluecolmedica" @click="turnCall(false)" :class="isAtril ? 'p-4 mt-5' : ''" class="text-center" size="sm" type="button" block :style="`margin-top: 20px; ${colorComputed}`")
            span(class="text-sizing-12" :style="isAtril ? 'font-size: 30px !important;' : ''") Sin turno preferencial
  .buttons-footer.Footer
    .d-flex(:class="isAtril ? 'justify-content-start' : 'justify-content-between'")
      b-button(variant="whitecolmedica" :class="isAtril ? 'atril-btn-back' : ''" class="text-center" size="sm" type="button" @click="goBack")
        svg(v-if="!isAtril" width='25' height='15' viewbox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M25 6.25H5.31944L10.2917 1.7625L8.33333 0L0 7.5L8.33333 15L10.2917 13.2375L5.31944 8.75H25V6.25Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
        svg(v-else width='58' height='35' viewbox='0 0 58 35' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M57.95 14.522H12.4635L23.9555 4.15114L19.4293 0.0779114L0.168945 17.4108L19.4293 34.7437L23.9555 30.6705L12.4635 20.2996H57.95V14.522Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
      b-button(v-if="!isAtril" variant="whitecolmedica" class="text-center" size="sm" type="button" @click="goHome")
        svg(width='22' height='19' viewbox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M21.5447 9.34433L11.5489 0.208078C11.4769 0.142118 11.3914 0.0897877 11.2973 0.054083C11.2032 0.0183783 11.1023 0 11.0005 0C10.8986 0 10.7977 0.0183783 10.7036 0.054083C10.6095 0.0897877 10.524 0.142118 10.452 0.208078L0.456229 9.34433C0.165019 9.6107 0 9.97251 0 10.3499C0 11.1334 0.696477 11.7705 1.55312 11.7705H2.60633V18.2897C2.60633 18.6826 2.95335 19 3.38289 19H9.44734V14.0279H12.1653V19H18.618C19.0476 19 19.3946 18.6826 19.3946 18.2897V11.7705H20.4478C20.8603 11.7705 21.2559 11.6217 21.5471 11.3532C22.1514 10.7982 22.1514 9.89926 21.5447 9.34433Z' fill='#FC0D0E' style='fill:#FC0D0E;fill:color(display-p3 0.9882 0.0510 0.0549);fill-opacity:1;')
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import WheelBarrow from "mdi-vue/WheelchairAccessibility";
import moment from "moment";

export default {
  name: "Normativity",

  components: {
    Header,
    Back,
    WheelBarrow
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  mounted() {
    if (this.currentUser.TipoIdentificacion === "RC") {
      if (this.isAtril) {
        document.body.style.backgroundImage =
          "url(" + require("@/assets/images/roboto_atril.png") + ")";
      } else {
        document.body.style.backgroundImage = "";
      }
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrow.currentUser,
      previousView: state => state.virtualrow.previousView,
      isAtril: state => state.virtualrow.isAtril,
      currentSite: state => state.virtualrow.currentSite,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      turnCall: "virtualrow/turnCall",
      goToView: "virtualrow/goToView"
    }),

    dontCallToEmergency() {
      this.priority = false;
    },

    actualTime() {
      const timer = setTimeout(() => {
        this.actualTime();
        clearTimeout(timer);
      }, 1000);
      let n = moment()
        .locale("es")
        .format("dddd DD [de] MMMM[,] hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
      return n[0].toUpperCase() + n.substring(1);
    },

    goHome() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "Home" });
    },

    actionActive() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    }
  }
};
</script>

<style lang="scss">
.Normativity {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.Normativity > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
  z-index: 50000;
}

.Article {
  max-width: 300px;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.buttons-footer {
  position: fixed;
  bottom: 40px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgba(223, 230, 237, 0);
  margin: auto;
  z-index: 1035;
  padding: 0 2rem;
  padding-top: 2rem;
}

.h1-mobile {
  font-size: 30px;
  color: var(--color-primary);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}

.blue-dk {
  color: #00599d;
}

.image {
  width: 100%;
  object-fit: cover;
}

.dk-pos-dk.isAtril {
  top: -90%;
}
.dk-pos-dk {
  position: absolute;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.size_icon {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.pad {
  text-align: end;
  font-weight: 400;
  color: var(--color-primary);
}

.sub-mobile {
  font-size: 20px;
  color: var(--color-primary);
}

.card-priority {
  background-color: #edeeee;
  border-radius: 19px;
  padding: 10px 20px;
  box-shadow: 0 0px 7px rgba(0, 0, 0, 0.75);
}

.h1 {
  font-size: 55px;
  color: var(--color-primary);
  font-weight: 900;
}

.box-red {
  color: #055ba4;
  font-size: 25px;
  font-weight: 400;
  padding-left: 10px;
  border-left: 2px solid red;
  max-width: 1280px;
  margin: 0 auto !important;
  margin-bottom: 1.25rem !important;
  display: block;
}

.atril-btn-back {
  width: 120px;
  height: 120px;
  svg {
    font-size: 100px;
  }
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .image {
    width: 50%;
  }
}
</style>
