<template lang="pug">
div(
  :style="isAtril ? 'color: #545454; width: 100%; margin: auto; min-height: 700px;' : 'margin: auto; width: 100%; font-size: 1.3rem;'"
  :class="isAtril ? '' : 'NoDate Container'"
)
  .Content(:style="!isAtril ? 'position: relative;' : ''")
    div.m-auto(:style="isAtril ? 'display: flex; justify-content: center; flex-direction: column; align-items: center;' : ''")
      .h(
        :class="isAtril ? 'h1 blue-text mt-3' : 'h1-mobile blue-text'" 
        :style="isAtril ? 'font-weight: 900 !important; top: 22% !important;' : 'max-width: 300px; display: block; margin: auto; text-align: center;'"
      ) {{ siteIsOffice ? "Oficina" : siteName.toLowerCase().includes("centro") ? "" : "Centro Médico" }} {{ siteName }}
      p.mt-3.box-red.mb-5(v-if="isAtril") {{ actualTime() }}
    p.mt-1.box-red.mb-4.mx-5(v-if="isAtril && name" :style="actionsChuck.length > 2 ? 'max-width: 1670px' : ''"): strong Hola {{ name }}
    //- p.mt-0.box-red.mb-2(v-if="isAtril && !isChildrenMode" :style="isAtril ? 'margin-left: 10rem; font-size: 30px;' : ''")
    //-   strong(style="font-size: 30px; color: #4090CF;") Hola Catalina
    //- FIXME: this is not working properly with the default font size of the default
    p.mt-0.mb-2.styles-faja(v-if="isAtril && isChildrenMode" :style="isAtril ? 'margin-left: 10rem; font-size: 30px;' : ''")
      svg(width='13' height='24' viewbox='0 0 13 24' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M1 1L12 12.2L1 23' stroke='#FC0D0E' style='stroke:#FC0D0E;stroke:color(display-p3 0.9176 0.2000 0.1333);stroke-opacity:1;')
      strong(style="font-size: 30px; color: #4090CF;").ml-2 {{ parent }}
    div(
      :style="isAtril ? 'margin-top: 30px; margin: auto; display: flex; flex-direction: row; justify-content: center; max-width: 1670px' : 'margin: 30px auto;'"
      :class="isAtril ? '' : 'buttons-dk'"
    )
      template(v-for="column in actionsChuck")
        div(:style="isAtril ? 'display: flex; justify-content: space-around; flex-direction: column;' : ''" :class="isAtril ? 'margin-atril-box' : ''")
          template(v-for="act in column")
            div(
              :key="act.actionCode"
              variant="bluecolmedica"
              :disabled="!!act.disabled"
              block
              @click="calculateAction(act)"
              :class="isAtril ? 'p-2 m-2 atril_text' : 'atril-mini mb-4'"
              :style="isAtril ? `${actionToShow.length <= 5 ? `min-width: ${column.length == 2 ? '600px' : '300px'}; ` : ''} height: ${column.length == 2 ? '250px' : '525px'}; align-items: center; flex-direction: column; justify-content: center;` : act.style"
            ).text-center.card-img
              img(:src="imgSelection(act.label)" :alt="act.label" :style="isAtril ? 'width: 100px; height: 100px' : 'width: 35px; height: 35px'")
              p.m-0 {{ act.label }}
              //- p.m-0(v-if="act.description && isAtril" :style="isAtril ? 'font-size: 20px; color: #393939;' : ''"): small {{ act.description }}
  .buttons.Footer
    .d-flex(:class="isAtril ? 'justify-content-start' : 'justify-content-between'")
      b-button(variant="whitecolmedica" :class="isAtril ? 'atril-btn-back' : ''" class="text-center" size="sm" type="button" @click="BackCalculed")
        svg(v-if="!isAtril" width='25' height='15' viewbox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M25 6.25H5.31944L10.2917 1.7625L8.33333 0L0 7.5L8.33333 15L10.2917 13.2375L5.31944 8.75H25V6.25Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
        svg(v-else width='58' height='35' viewbox='0 0 58 35' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M57.95 14.522H12.4635L23.9555 4.15114L19.4293 0.0779114L0.168945 17.4108L19.4293 34.7437L23.9555 30.6705L12.4635 20.2996H57.95V14.522Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
      b-button(v-if="!isAtril" variant="whitecolmedica" class="text-center" size="sm" type="button" @click="goHome")
        svg(width='22' height='19' viewbox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M21.5447 9.34433L11.5489 0.208078C11.4769 0.142118 11.3914 0.0897877 11.2973 0.054083C11.2032 0.0183783 11.1023 0 11.0005 0C10.8986 0 10.7977 0.0183783 10.7036 0.054083C10.6095 0.0897877 10.524 0.142118 10.452 0.208078L0.456229 9.34433C0.165019 9.6107 0 9.97251 0 10.3499C0 11.1334 0.696477 11.7705 1.55312 11.7705H2.60633V18.2897C2.60633 18.6826 2.95335 19 3.38289 19H9.44734V14.0279H12.1653V19H18.618C19.0476 19 19.3946 18.6826 19.3946 18.2897V11.7705H20.4478C20.8603 11.7705 21.2559 11.6217 21.5471 11.3532C22.1514 10.7982 22.1514 9.89926 21.5447 9.34433Z' fill='#FC0D0E' style='fill:#FC0D0E;fill:color(display-p3 0.9882 0.0510 0.0549);fill-opacity:1;')
</template>

<script>
import { mapState, mapActions } from "vuex";
import { chunk as _chunk } from "lodash";
import moment from "moment";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";

import ConsultasSrc from "../../assets/images/consultas.svg";
import ConsultaSrc from "../../assets/images/consulta.svg";
import LabSrc from "../../assets/images/lab.svg";
import OtroSrc from "../../assets/images/otro.svg";
import PagoSrc from "../../assets/images/pago.svg";
import ClientesSrc from "../../assets/images/clientes.svg";
import ExamenesSrc from "../../assets/images/examenes.svg";
import ProcedureSrc from "../../assets/images/procedure.svg";
import ServicioSrc from "../../assets/images/servicio.svg";

export default {
  name: "NoDate",

  components: {
    Header,
    Back
  },

  data: () => ({
    parentList: [],
    actionToShow: [],
    parent: "",
    isChildrenMode: false
  }),

  created() {
    this.setLocalActions(this.menu);
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  mounted() {
    if (this.currentUser.TipoIdentificacion === "RC" && this.isAtril) {
      if (this.isAtril) {
        document.body.style.backgroundImage =
          "url(" + require("@/assets/images/jirafa_atril.png") + ")";
      } else {
        document.body.style.backgroundImage =
          "url(" + require("@/assets/images/doggy.png") + ")";
      }
    }
  },

  computed: {
    ...mapState({
      menu: state => state.virtualrow.menu,
      currentUser: state => state.virtualrow.currentUser,
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    name() {
      return this.currentUser.Nombres;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteName() {
      return this.currentSite?.name;
    },

    actionsChuck() {
      console.log(this.actionToShow, "actionToShow");
      let _actionToShow = this.actionToShow.filter(a => !a.hidden);
      return _chunk(_actionToShow, 2);
    }
  },
  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      goToView: "virtualrow/goToView",
      setAction: "virtualrow/setAction"
    }),

    actualTime() {
      const timer = setTimeout(() => {
        this.actualTime();
        clearTimeout(timer);
      }, 1000);
      let n = moment()
        .locale("es")
        .format("dddd DD [de] MMMM[,] hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
      return n[0].toUpperCase() + n.substring(1);
    },

    imgSelection(label) {
      const sources = {
        "Consultas programadas": ConsultasSrc,
        "Laboratorio clínico": LabSrc,
        "Consulta prioritaria": ConsultaSrc,
        "Pago de mi plan": PagoSrc,
        "Otros servicios": OtroSrc,
        Autorizaciones: ConsultasSrc,
        "Servicio al cliente": ServicioSrc,
        "Apoyo Terapéutico Y Apoyo Diagnóstico": ClientesSrc,
        "Servicios Odontológicos": OtroSrc,
        "Consulta Odontológica Prioritaria": ConsultaSrc,
        "Consulta Odontológica Programada": ConsultasSrc,
        Caja: PagoSrc,
        "Gestión Clientes": ClientesSrc,
        "Exámenes diagnósticos": ExamenesSrc,
        "Procedimientos quirúrgicos": ProcedureSrc,
        Medicamentos: LabSrc
      };
      return sources[label];
    },

    setLocalActions(actions) {
      actions = JSON.parse(JSON.stringify(actions));
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].hidden && typeof actions[i].hidden === "object") {
          actions[i].hidden =
            (actions[i].hidden.mobile && !this.isAtril) ||
            (actions[i].hidden.atril && this.isAtril) ||
            (actions[i].hidden.client && this.currentUser?.Tipo != "C");
        }
      }
      this.actionToShow = [...actions];
    },

    calculateAction(act) {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if (act?.children) {
        if (this.isAtril) {
          this.parent = act.label;
          this.isChildrenMode = true;
        }
        this.parentList.push(this.actionToShow);
        return this.setLocalActions(act.children);
      }
      if (this.isAtril) this.isChildrenMode = false;
      this.setAction(act);
    },

    BackCalculed() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if (this.parentList.length) {
        this.parent = "";
        this.isChildrenMode = false;
        this.setLocalActions(this.parentList.pop());
      } else this.goBack();
    },

    goHome() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "Home" });
    }
  }
};
</script>

<style scoped lang="scss">
.atril_text {
  font-size: 2rem;
}
.atril-mini {
  font-size: 1.3rem;
}
.NoDate {
  position: relative;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  overflow-x: hidden;
}

.NoDate > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
  position: fixed;
  z-index: 50000;
}

.box-dk {
  max-width: 365px;
  margin: 1.4rem auto;
  text-align: center;
  display: block;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
  width: 100%;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.blue-text {
  color: #055ba4;
}

.box-red {
  color: #055ba4;
  font-size: 25px;
  font-weight: 400;
  padding-left: 10px;
  border-left: 2px solid red;
  max-width: 1280px;
  margin: 0 auto !important;
  margin-bottom: 1.25rem !important;
  display: block;
}

.styles-faja {
  max-width: 1280px;
  margin: 0 auto !important;
  margin-bottom: 1rem !important;
  display: block;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.buttons {
  position: fixed;
  bottom: 40px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgba(223, 230, 237, 0);
  margin: auto;
  z-index: 1035;
  padding: 0 2rem;
  padding-top: 2rem;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  text-align: center;
  width: 100%;
  min-height: 20rem;
}

svg[fill="currentColor"] {
  width: 50px;
  height: 50px;
  /* fill: gray; */
}

button svg[fill="currentColor"] {
  /* fill: white; */
  width: auto;
  height: auto;
}

.dk-pos-dk {
  position: absolute;
  top: -30%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.h1 {
  font-size: 55px;
  color: var(--color-primary);
  font-weight: 900;
}

.h1-mobile {
  font-size: 30px;
  color: var(--color-primary);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}

.card-img {
  border-radius: 19px;
  font-weight: 400;
  padding: 16px;
  color: var(--color-primary);
  background-color: #edeeee;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  gap: 10px;
}

.card-img img {
  -o-object-fit: cover;
  object-fit: contain;
}

.margin-atril-box {
  margin-right: 30px;
}

.atril-btn-back {
  width: 120px;
  height: 120px;
  svg {
    font-size: 100px;
  }
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -10%;
  }
}
</style>
